.container {
  flex-grow: 1;
  padding: 20px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.filterRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.filter {
  height: 48px;
  width: 48px;
  background: var(--white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterIcon {
  color: var(--secondary);
}
.map {
  width: 100%;
  height: 100vh;
  position: relative;
}
.map img {
  width: 100%;
  height: 100%;
}
.topGrid {
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 20px;
  }
  .map {
    width: 100%;
    height: 100vh;
    margin-top: 20px;
  }
  .filterRow {
    align-items: center;
  }
}
