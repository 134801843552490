.container {
	flex-grow: 1;
	padding: 20px;
}
.cardsRow {
	display: flex;
	align-items: center;
}
.column {
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin-top: 15px;
	background-color: var(--white);
	border-radius: 12px;
	padding-bottom: 20px;
}
.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.filterRow {
	padding: 10px 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--border);
	margin-bottom: 20px;
}
.tasksRow {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: flex-start;
}
.filter {
	height: 48px;
	width: 48px;
	background: rgba(143, 149, 178, 0.1);
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.filterIcon {
	color: var(--secondary);
}
.heading {
	text-align: center;
	padding: 16px 0px;
}
.leftGrid {
	width: 80%;
}
.rightGrid {
	background-color: var(--white);
	border-radius: 12px;
}

@media only screen and (max-width: 768px) {
	.container {
		padding: 20px;
		width: 100%;
	}
	.column {
		padding: 15px 20px 20px 20px;
	}
	.cardsRow {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.filterRow {
		flex-direction: column;
		align-items: flex-start;
	}
	.row {
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.rightGrid {
		margin-top: 20px !important;
	}
}
