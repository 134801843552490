.container {
  height: 90px;
  border: 1px solid var(--border);
  border-radius: 12px;
  padding: 20px 15px 15px 20px;
  position: relative;
  margin-top: 20px;
}
.container textarea {
  resize: none;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.container Button {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

@media only screen and (max-width: 768px) {
  .container {
    margin: 20px 0px;
  }
}
