.card {
  background-color: var(--white);
  border-radius: 12px;
  margin-bottom: 20px;
  height: 75vh;
}
.headerRow {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}
.viewAll {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary);
  cursor: pointer;
}
.container {
  padding: 12px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border);
}
.row {
  display: flex;
}
.img {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  margin-right: 8px;
}
.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.message {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary);
}
.details {
  font-weight: 400;
  font-size: 10px !important;
  line-height: 14px;
  border-radius: 30px !important;
  color: var(--secondary) !important;
  text-transform: capitalize !important;
}
