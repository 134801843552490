.card {
  position: absolute;
  bottom: 15px;
  right: 50px;
  width: 400px;
  box-shadow: 0px 0px 50px rgba(26, 25, 33, 0.03);
  border-radius: 12px;
  background-color: var(--white);
  z-index: 1;
  padding: 20px 0px;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
.justRow {
  display: flex;
  align-items: center;
}
.justRow div {
  width: 20px;
  height: 20px;
  background-color: var(--hired);
  color: var(--white);
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  border-radius: 100%;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.justRow span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
}
.clockedin {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}
.profileWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 18px;
}
.profileWrapper img {
  height: 45px;
  width: 45px;
}
.profileWrapper div {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: var(--black);
  margin-top: 3px;
  margin-bottom: 4px;
}
.profileWrapper span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
}
.ratingRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.iconsRow {
  display: flex;
  align-items: center;
}
.icon {
  width: 35px;
  height: 35px;
  background: var(--border);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.iconText div {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
}
.iconText span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary);
}
.day {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: var(--black);
  padding: 0px 20px;
}
.statusRow {
  margin: 20px 20px 0px 20px;
  padding-top: 10px;
  display: flex;
  border-top: 1px solid var(--border);
}
.ondutyBadge {
  background-color: var(--success);
  height: 8px;
  width: 8px;
  border-radius: 100%;
  margin-right: 5px;
}
.wayBadge {
  background-color: var(--primary);
  height: 8px;
  width: 8px;
  border-radius: 100%;
  margin-right: 5px;
  margin-left: 15px;
}
.restBadge {
  background-color: var(--reported);
  height: 8px;
  width: 8px;
  border-radius: 100%;
  margin-right: 5px;
  margin-left: 15px;
}
.badgeRow {
  display: flex;
  align-items: center;
}
.label {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: var(--secondary);
}
.ondutyBar {
  width: 88px;
  height: 10px;
  background-color: var(--success);
  border-radius: 12px;
}
.wayBar {
  width: 35px;
  height: 10px;
  background-color: var(--primary);
  border-radius: 12px;
}
.resBar {
  width: 88px;
  height: 10px;
  background-color: var(--reported);
  border-radius: 12px;
}
.fieldRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 20px;
}
.fieldRow span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3f5263;
  display: flex;
  align-items: center;
}
.fieldRow div {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3f5263;
  display: flex;
  align-items: center;
}
.fieldIcon {
  margin-right: 8px;
}
.amount {
  color: #e3032a !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.time {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}
