.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid var(--border);
	padding: 14px 0px;
}
.row {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 10px;
}
.bullet {
	height: 8px;
	width: 8px;
	background-color: var(--black);
	border-radius: 100%;
}
.task {
	color: var(--black);
	max-width: 80%;
	margin-left: 10px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}
.checkbox {
	height: 16px;
	width: 16px;
	margin-right: 10px !important;
}

@media only screen and (max-width: 768px) {
	.task {
		color: var(--black);
		max-width: 95%;
		margin-left: 10px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}
}
