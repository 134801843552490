.header {
	height: 80px;
	width: 100%;
	border-bottom: 1px solid var(--border);
}

@media only screen and (max-width: 768px) {
	.header {
		height: auto;
		width: 100%;
		margin-top: 60px;
		border-bottom: none;
	}
}
