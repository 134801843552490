.header {
  height: 80px;
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.header p {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: var(--black);
}
.header div {
  font-weight: 700;
  font-size: 26px;
  line-height: 48px;
  color: var(--black);
}
.time {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--secondary);
}

@media only screen and (max-width: 768px) {
  .header {
    height: auto;
    width: 100%;
    margin-top: 50px;
    align-items: flex-start;
  }
}
