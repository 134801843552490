.container {
  flex-grow: 1;
  padding: 20px;
}
.filterRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filter {
  height: 48px;
  width: 48px;
  background: rgba(143, 149, 178, 0.1);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterIcon {
  color: var(--secondary);
}
.tabsRow {
  background: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}
.tabsView {
  box-shadow: 0px 0px 50px rgba(26, 25, 33, 0.03);
  border: 1px solid var(--border);
  border-radius: 62px;
  margin: auto;
}
.tab {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  border-radius: 62px !important;
  color: var(--secondary);
}
.calendarCard {
  border-radius: 12px;
  background-color: var(--white);
  padding-bottom: 5px;
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 20px;
    margin-top: 50px;
  }
  .tabsRow {
    padding: 15px 10px;
  }
  .tab {
    font-weight: 500;
    font-size: 12px !important;
    line-height: 20px;
    text-transform: capitalize;
    border-radius: 62px !important;
    color: var(--secondary);
    padding: 0px 5px !important;
  }
}
