.h_datePicker {
  border: none;
  width: 100px;
}

.h_datePicker input {
  border: none;
  color: #081735;
  font-size: 1rem;
  cursor: default;
}

.h_datePicker input::placeholder {
  color: #081735;
  font-size: 1rem;
}
