.header {
  height: 80px;
  width: 100%;
  border-bottom: 1px solid var(--border);
}
.gridContainer {
  padding: 0px 20px;
  margin-top: 20px;
}
.leftGrid {
  background-color: var(--white);
  padding: 10px 30px;
  border-radius: 12px;
}
.userImg {
  height: 185px;
  width: 185px;
  margin-right: 25px;
  border-radius: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.input {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  border-bottom: var(--secondary);
  padding: 6px 0 !important;
}
.btnRow {
  margin-top: 20px;
}
.col {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.heading {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .header {
    height: 60px;
    border-bottom: none;
  }
  .userImg {
    height: 100px;
    width: 100px;
    border-radius: 100%;
  }
  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* .grid {
    margin-top: 20px !important;
  } */
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .col {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
  .input {
    width: 100%;
  }
  .btnRow {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
