.logoWrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.logo {
  height: 80px;
}
.menuWrapper {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-top: 40%;
  padding-bottom: 30px;
  cursor: pointer;
}
.logoutIcon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}
.logoutText {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
}
.userCard {
  /* margin-left: 40px; */
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 10px;
}
.userCard img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}
.username {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
}
.role {
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
}
.arrow {
  width: 8px !important;
  height: 14px !important;
  margin-left: 25px;
}

@media only screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
