.card {
  padding: 15px;
  background-color: var(--white);
  border-top: 1px solid var(--border);
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary);
}
.chip {
  height: 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.open {
  background-color: var(--purpleRgba) !important;
  color: var(--primary) !important;
}
.live {
  background-color: var(--successRgba) !important;
  color: var(--success) !important;
}
.reported {
  background-color: var(--reportedRgba) !important;
  color: var(--reported) !important;
}
.cancelled {
  background-color: var(--cancelledRgba) !important;
  color: var(--cancelled) !important;
}
.completed {
  background-color: var(--completedRgba) !important;
  color: var(--black) !important;
}
.hired {
  background-color: var(--hiredRgba) !important;
  color: var(--hired) !important;
}
