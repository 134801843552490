.container {
	padding-bottom: 15px;
	border-bottom: 1px solid var(--border);
}
.row {
	display: flex;
	align-items: center;
	padding: 0px 20px;
}
.column1 {
	flex-direction: column;
	width: 40%;
	margin-top: 5px;
}
.column2 {
	flex-direction: column;
	width: 45%;
	margin-top: 5px;
}
.column3 {
	flex-direction: column;
	width: 15%;
	margin-top: 5px;
}
.buttonRow {
	display: flex;
	align-items: center;
}
.buttonCol {
	display: flex;
	flex-direction: column;
	width: 80%;
}
.heading {
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	color: var(--black);
}
.label {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: var(--secondary);
	text-align: left;
}
.reported {
	color: var(--reported);
}
.btnView {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: var(--secondary) !important;
	border-radius: 15px !important;
}
