.row {
  display: flex;
  justify-content: left;
}
.outerCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  margin-right: 10px;
}
.outer_circle_unseccessfull {
  border: 1px solid var(--cancelled);
}
.outer_circle_unseccess {
  border: 1px solid var(--success);
}
.inner_circle {
  position: absolute;
  border-radius: 100%;
  height: 12px;
  width: 12px;
}
.inner_circle_unseccessfull {
  background: var(--cancelled);
}
.inner_circle_seccessfull {
  background-color: var(--success);
}
.progress {
  width: 2px;
  height: 30px;
  margin-left: 48px;
  margin-top: -10px;
}
.currentProgress {
  background-color: var(--success);
}
.notCurrentProgress {
  background-color: var(--cancelled);
}
.dateTimeBox {
  width: 40px;
  text-align: left;
}
.time {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: var(--secondary);
  max-width: 40px;
  margin-right: 10px;
}
.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
}
