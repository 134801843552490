body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #6c5dd3;
  --secondary: #8f95b2;
  --white: #ffffff;
  --black: #081735;
  --background: #f5f5f5;
  --success: #219653;
  --reported: #f2994a;
  --cancelled: #ff442b;
  --hired: #ffa2c0;
  --purpleRgba: rgba(108, 93, 211, 0.1);
  --successRgba: rgba(33, 150, 83, 0.1);
  --reportedRgba: rgba(242, 153, 74, 0.1);
  --cancelledRgba: rgba(255, 68, 43, 0.1);
  --completedRgba: rgba(8, 23, 53, 0.1);
  --hiredRgba: rgba(255, 162, 192, 0.1);
  --border: rgba(8, 23, 53, 0.1);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
