.calendarWrapper {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--border);
  padding: 10px 0px;
}
.calendar {
  border: none !important;
  background-color: transparent;
}
.filterRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}
.filter {
  height: 48px;
  width: 48px;
  background: rgba(143, 149, 178, 0.1);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterIcon {
  color: var(--secondary);
}
.shiftReports {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary);
}
