.card {
	width: 31%;
	height: 165px;
	background: var(--white);
	box-shadow: 0px 0px 50px rgba(26, 25, 33, 0.03);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 10px 0px 10px 2%;
}
.cardHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px 15px;
	height: 45px;
	border-bottom: 1px solid var(--border);
}
.cardHeader div {
	max-width: 80% !important;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: black;
}
.horizontalSeparator {
	height: 100%;
	width: 1px;
	background-color: var(--border);
}
.eye {
	height: 20px;
	width: 20px;
	color: var(--black);
}
.details {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: var(--secondary);
}

@media only screen and (max-width: 768px) {
	.card {
		width: 100%;
		height: 100%;
	}
}
