.container {
  background-color: var(--white);
  border-radius: 12px;
}
.heading {
  padding: 16px 0px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}
