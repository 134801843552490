.container {
  flex-grow: 1;
}
.gridContainer {
  padding: 20px;
}
.facilityView {
  background: var(--white);
  box-shadow: 0px 0px 50px rgba(26, 25, 33, 0.03);
  border-radius: 12px;
  padding-bottom: 20px;
}
.tab {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 20px 10px;
}

@media only screen and (max-width: 768px) {
  .gridContainer {
    padding: 20px;
  }
}
