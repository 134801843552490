.container {
  padding: 30px 20px;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 50px rgba(26, 25, 33, 0.03);
  border-radius: 12px;
  margin-top: 20px;
}
progress {
  width: 100px;
  height: 100px;
}
.outerGraph {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}
.inner_circle {
  position: absolute;
  border-radius: 100%;
  height: 80px;
  width: 80px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.graphLabel {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin-top: 15px;
}
.text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary);
}

@media only screen and (max-width: 768px) {
  .container {
    flex-wrap: wrap;
  }
  .text {
    margin-top: 20px;
  }
}
