.container {
  background-color: var(--white);
  border-radius: 12px;
  margin-bottom: 10px;
}
.heading {
  padding: 16px 0px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  border-bottom: 1px solid var(--border);
}
.cardBody {
  padding: 15px;
}
.row {
  display: flex;
  justify-content: space-between;
}
.subHeading {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
  margin-top: 14px;
}
.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
}
.btn {
  border-radius: 15px !important;
}
.refund {
  color: var(--reported) !important;
  border-color: var(--reported) !important;
}
.payoff {
  color: var(--success) !important;
  border-color: var(--success) !important;
}
