.card {
  width: 160px;
  height: 130px;
  background: var(--white);
  box-shadow: 0px 0px 50px rgba(26, 25, 33, 0.03);
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-right: 20px;
}
.card p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
}
.card h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: var(--black);
}
.card button {
  width: 65px;
  height: 26px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
  border: 1px solid var(--secondary);
  background-color: var(--white);
  border-radius: 33px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .card {
    width: 32.5%;
    margin-right: 0px;
    height: 150px;
  }
}
