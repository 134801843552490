.inputContainer {
  padding-bottom: 20px;
  position: absolute;
  bottom: 0px;
  min-height: 55px;
  max-height: 200px;
  width: 98%;
  background-color: var(--white);
}
.container {
  border: 1px solid var(--border);
  border-radius: 59px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 15px;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.emoji {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: var(--secondary);
}
.add {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: var(--secondary);
}
.input {
  resize: none;
  border: none;
  width: 100%;
  outline: none;
  min-height: 20px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-top: 15px;
}
.input::placeholder {
  color: var(--secondary);
}
.send {
  width: 32px !important;
  height: 32px !important;
  border-radius: 4px;
  background-color: var(--primary);
  border: none;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.sendIcon {
  height: 16px !important;
  width: 16px !important;
}
