.container {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.imageWrapper {
  position: relative;
}
.imageWrapper img {
  height: 38px;
  width: 38px;
  border-radius: 100%;
  margin-right: 10px;
}
.onlineBadge {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 100%;
  position: absolute;
  top: 0px;
  right: 10px;
}
.name {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}
.status {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary);
}
.separator {
  background-color: var(--secondary);
  height: 20px;
  width: 1px;
  margin: 0px 20px;
}
.online {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
}
.more {
  color: var(--secondary);
  width: 20px;
}

.flex {
  display: flex;
  flex-direction: column;
}

.date {
  color: #92929d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
  letter-spacing: 0.00625rem;
}
