.card {
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px 16px 16px;
  background: var(--white);
  box-shadow: 0px 0px 50px rgba(26, 25, 33, 0.03);
  border-radius: 12px;
  /* margin: 10px 0px; */
}
.heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  margin-bottom: 4px;
}
.subHeading {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
  margin-bottom: 4px;
}
.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shift {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
}
.arrow {
  width: 32px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}
.chipRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  padding-right: 16px;
}
.chip {
  background-color: rgba(33, 150, 83, 0.1) !important;
  color: #219653 !important;
}
.date {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
}

@media only screen and (max-width: 768px) {
  .card {
    width: 100%;
  }
}
