.row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 25px 20px;
}
.indicators {
	cursor: pointer;
}
.nextPrevRow {
	display: flex;
	justify-content: center;
	align-items: center;
}
.curMonth {
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	color: var(--black);
}
.curDate {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: var(--secondary);
}
.shifts {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--secondary);
}
.card {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 25px 20px;
	border-top: 1px solid var(--border);
	border-bottom: 1px solid var(--border);
}
.column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 60px;
	border-radius: 12px;
	cursor: pointer;
}
.date {
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: var(--black);
}
.day {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: var(--secondary);
}
.selectedDate {
	background-color: var(--primary);
}
.selectedDateColor {
	color: var(--white);
}

@media only screen and (max-width: 768px) {
	.row {
		padding: 25px 5px;
	}
	.curMonth {
		text-align: center;
	}
}
