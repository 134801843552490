.searchContainer {
  background: rgba(143, 149, 178, 0.1) !important;
  border-radius: 12px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  width: 100%;
}
.icon {
  height: 18px;
  width: 18px;
  color: var(--secondary);
}
.input {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px;
  color: var(--secondary) !important;
}
