.container {
  flex-grow: 1;
  padding: 20px;
}
.filterRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filter {
  height: 48px;
  width: 48px;
  background: var(--white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterIcon {
  color: var(--secondary);
}
.shiftRow {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 10px;
  margin-bottom: 10px;
}
.invoicesCard {
  box-shadow: 0px 0px 50px rgba(26, 25, 33, 0.03);
  border-radius: 12px;
  margin-bottom: 20px;
  background-color: var(--white);
}
.heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  padding: 18px 15px;
  border-bottom: 1px solid var(--border);
}
.calendar {
  background-color: var(--white);
  border: none !important;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 20px;
    margin-top: 50px;
  }
}
