.container {
  flex-grow: 1;
  height: 100vh;
  overflow: hidden;
}
.gridContainer {
  padding: 0px 20px;
  height: 100vh;
  padding-top: 10px;
}
.leftGrid {
  background-color: var(--white);
  padding: '5px 0px';
  border-right: 1px solid var(--border);
  overflow-y: 'scroll';
  max-height: 100vh;
}
.rightGrid {
  background-color: var(--white);
  overflow-y: 'auto';
  max-height: 100vh;
  position: relative;
  overflow-y: auto;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  height: 50px;
  margin-bottom: 25px;
}

.column {
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  height: auto;
  margin-bottom: 25px;
}

.heading {
  font-weight: 700;
  font-size: 26px;
  line-height: 48px;
  padding-top: 28px;
}

.messageTopHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.scroll {
  overflow-y: auto;
  min-height: 80vh;
  padding-bottom: 300px;
  width: 100%;
  padding: 0 10px;
  height: 80vh;
}

.header {
  /* height: 80px; */
  width: 100%;
  border-bottom: 1px solid var(--border);
  margin-bottom: 20px;
}

@media only screen and (max-width: 900px) {
  .container {
    overflow: hidden;
    margin-top: 80px;
    height: calc(100vh - 80px);
  }
  .rightGrid {
    height: calc(100vh - 80px);
  }
  .leftGrid {
    display: none;
  }
}
