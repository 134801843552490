.container {
  background-color: var(--white);
  border-radius: 12px;
  margin-bottom: 20px;
}
.heading {
  padding: 16px 0px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}
.lateContainer {
  border-top: 1px solid var(--border);
  padding: 15px;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.name {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary);
}
.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
}
.hidden {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
}
.extend {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize !important;
}
.cancel {
  color: var(--cancelled) !important;
  border-color: var(--cancelled) !important;
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize !important;
}

@media only screen and (max-width: 768px) {
  .container {
    margin-top: 20px;
  }
}
